import { useEffect, useState } from "react";
import "../styles/reports.scss";
import Header from "../components/header";
import Footer from "../components/footer";
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";

export default function Reports() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/api/reports")
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Failed to fetch reports.");
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="body">
      <Banner />
      <Header toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />}

      <div className="image2">
        <div id="newconnection">
          <p>Reports</p>
        </div>
      </div>

      <div className="reports-section">
        {data?.length > 0 ? (
          <div className="reports-list">
            {data.map((report, index) => (
              <div 
                key={index} 
                className="report-item" 
                onClick={() => window.open(`/api/uploads/reports/${report.File}`, "_blank", "noopener,noreferrer")}
                style={{ cursor: "pointer" }} // Ensures it looks clickable
              >
                <span className="report-index">{index + 1}.</span>
                <span className="report-title">{report.Title}</span>
                <span className="report-date">{new Date(report.Date).toLocaleDateString()}</span>
              </div>
            
            ))}
          </div>
        ) : (
          <div className="no-reports">
            <p>No current reports available.</p>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}
