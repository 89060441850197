import React, { useState, useEffect } from "react";
import "../styles/carousel.scss";

const Carousel = ({ images, interval = 6000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitionType, setTransitionType] = useState("slide-left");

  const transitions = ["slide-left", "fade", "scale", "rotate"]; // Array of transition classes

  // Function to go to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
    randomizeTransition();
  };

  // Function to go to the previous image
  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    randomizeTransition();
  };

  // Auto-slide functionality using useEffect
  useEffect(() => {
    const autoSlide = setInterval(() => {
      nextImage(); // Shift to the next image every X seconds
    }, interval);

    // Clear interval on component unmount to avoid memory leaks
    return () => clearInterval(autoSlide);
  }, [currentIndex, interval, images.length]);

  // Randomly select a transition
  const randomizeTransition = () => {
    const randomIndex = Math.floor(Math.random() * transitions.length);
    setTransitionType(transitions[randomIndex]);
  };

  return (
    <div className="carousel">
      <div
        className="carousel__container"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="carousel__image"
            style={{ backgroundImage: `url(${image})` }}
          />
        ))}
      </div>
      <button className="carousel__btn prev" onClick={prevImage}>
        {'<<'}
      </button>
      <button className="carousel__btn next" onClick={nextImage}>
        {'>>'}
      </button>
    </div>
  );
};

export default Carousel;
