import { useEffect, useState } from "react";
import "../styles/management.scss";
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";
import personicon from "../assets/imgs/personicon.jpg";
import md from "../assets/imgs/management/MD.jpg";
import tm from "../assets/imgs/management/tm.jpg";
import fm from "../assets/imgs/management/fm.png";
import cm from "../assets/imgs/management/cm.png";
import accountant from "../assets/imgs/management/accountant.jpg";
import senioreng from "../assets/imgs/management/senioreng.jpg";
import billing from "../assets/imgs/management/billing.jpg";
import procurement from "../assets/imgs/management/procurement.jpg";
import hr from "../assets/imgs/management/hr.jpg";
import internalaudit from "../assets/imgs/management/internalaudit.jpg";
import komothai from "../assets/imgs/management/komothaische.jpg";
import githunguri from "../assets/imgs/management/githungurische.jpg";
import gis from "../assets/imgs/management/gis.jpg";
import procurementassist from "../assets/imgs/management/procurementassist.jpg";

export default function Management (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  
  return (
    <div className="body">
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="image">
        <div>
          <p>Our Management Team</p>
        </div>
      </div>
      <div className="management">
        <div className="transpbg">
          <div className="persons">
            <div className="person" onClick={() => window.location.href = window.location.pathname + "/md"}>
                <div className="photo">
                  <img src={md} alt="" />
                </div>
                <div className="details">
                  <h3>Francis Kahuha</h3>
                  <p>Managing Director</p>
                </div>
            </div>
          </div>
          <div className="persons">
            <div className="person">
              <div className="photo">
                <img src={fm} alt="" />
              </div>
              <div className="details">
                <h3>Daniel Muiruri</h3>
                <p>Finance Manager</p>
              </div>
            </div>
            {/* <div className="person">
                <div className="photo">
                  <img src={tm} alt="" />
                </div>
                <div className="details">
                  <h3>Willy Kamau</h3>
                  <p>Technical Manager</p>
                </div>
            </div> */}
            <div className="person">
                <div className="photo">
                  <img src={tm} alt="" />
                </div>
                <div className="details">
                  <h3>Christopher Gatheca</h3>
                  <p>Ag. Technical Manager</p>
                </div>
            </div>
            <div className="person" onClick={() => window.location.href = window.location.pathname + "/cm"}>
                <div className="photo">
                  <img src={cm} alt="" />
                </div>
                <div className="details">
                  <h3>Daniel Muriuki</h3>
                  <p>Commercial Manager</p>
                </div>
            </div>
            {/* <div className="person">
                <div className="photo">
                  <img src={hr} alt="" />
                </div>
                <div className="details">
                  <h3>Anne Nyambura Maina</h3>
                  <p>Human Resource and Admin Officer</p>
                </div>
            </div> */}
            <div className="person">
                <div className="photo">
                  <img src={procurement} alt="" />
                </div>
                <div className="details">
                  <h3>Cecilia Wanjiru Gakuha</h3>
                  <p>Head of Supply Chain</p>
                </div>
            </div>
            <div className="person">
                <div className="photo">
                  <img src={billing} alt="" />
                </div>
                <div className="details">
                  <h3>Peris Wambui Gichuhi</h3>
                  <p>Human Resource Officer</p>
                </div>
            </div>
            <div className="person">
                <div className="photo">
                  <img src={accountant} alt="" />
                </div>
                <div className="details">
                  <h3>Alex Waruhiu</h3>
                  <p>Accountant</p>
                </div>
            </div>
            {/* <div className="person">
                <div className="photo">
                  <img src={senioreng} alt="" />
                </div>
                <div className="details">
                  <h3>Christopher Gatheca</h3>
                  <p>Senior Engineer</p>
                </div>
            </div> */}
            <div className="person">
                <div className="photo">
                  <img src={internalaudit} alt="" />
                </div>
                <div className="details">
                  <h3>A. Wambii Gachiku Maina</h3>
                  <p>Audit, Risk and Compliance</p>
                </div>
            </div>
            <div className="person">
                <div className="photo">
                  <img src={komothai} alt="" />
                </div>
                <div className="details">
                  <h3>Joseph King'ori</h3>
                  <p>Drinking Water Manager</p>
                </div>
            </div>
            <div className="person">
                <div className="photo">
                  <img src={githunguri} alt="" />
                </div>
                <div className="details">
                  <h3>Gabriel Kariuki</h3>
                  <p>Drinking Water Manager</p>
                </div>
            </div>
            <div className="person" onClick={() => window.location.href = window.location.pathname + "/gis"}>
                <div className="photo">
                  <img src={gis} alt="" />
                </div>
                <div className="details">
                  <h3>James Mwenda Kaburu</h3>
                  <p>GIS Officer</p>
                </div>
            </div>
            <div className="person">
                <div className="photo">
                  <img src={procurementassist} alt="" />
                </div>
                <div className="details">
                  <h3>Rachael Njoki Mwaura</h3>
                  <p>Supply Chain Officer</p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}