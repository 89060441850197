import "../../styles/header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMoon, faSun} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/imgs/giwasco_logo.png"
import { useState, useEffect } from "react";
import Dropdown from "../dropdown";

export default function StaffHeader (props) {
    const [isSlightlyScrolled, setIsSlightlyScrolled] = useState(false);
    const [isScrolledByVh, setIsScrolledByVh] = useState(false);
    const [toggleMenu, setToggleMenu] = useState(false);

    // When the user scrolls, run this function...  
    useEffect(() => {
        const handleScroll = () => {
        if (window.pageYOffset > 70 && window.pageYOffset < window.innerHeight+100) {
            setIsSlightlyScrolled(true);
        } else {
            setIsSlightlyScrolled(false);
        }
        
        if (window.pageYOffset > window.innerHeight+100) {
            setIsScrolledByVh(true);
        } else {
            setIsScrolledByVh(false);
        }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleContextMenu = (e) => {
        e.preventDefault();
        };

        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    return (
        <div className= {isScrolledByVh ?  "header scrolledByVh" : isSlightlyScrolled ? "header" : "header nobg"}>
            <div className="navigation">
                <div className="logo">
                    <img src={logo} alt="" />
                    {/* <h1>Githunguri Water & Sanitation Company Ltd.</h1> */}
                </div>
                <div className="navlinks">
                    <a href="/">Home</a>
                    <Dropdown title="Media" items = {
                        {"View Reports" : "/admin/reports", 
                            "Post new media" : "/admin/postmedia" }} isSlightlyScrolled={isSlightlyScrolled}/>
                    <Dropdown title="Careers" items = {
                        {"View Careers" : "/admin/careers", 
                            "Post new career" : "/admin/postcareer" }} isSlightlyScrolled={isSlightlyScrolled}/>
                    <Dropdown title="Tenders" items = {
                        {"View Tenders" : "/admin/tenders", 
                            "Post new tender" : "/admin/posttender" }} isSlightlyScrolled={isSlightlyScrolled}/>
                    <a href="/gis">GIS</a>
                </div>
                <div className="menu">
                    <FontAwesomeIcon icon={faBars} onClick={() => props.setToggleMenu(!props.toggleMenu)}/>
                </div>
            </div>
        </div>
    );
}