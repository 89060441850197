import { useEffect, useState } from "react";
import "../../styles/reports.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import MenuTab from "../../components/menu_tab";
import Banner from "../../components/banner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";

export default function ViewReports() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/api/reports")
      .then((res) => res.ok ? res.json() : Promise.reject("Failed to fetch reports."))
      .then(setData)
      .catch(console.error);
  }, []);
  

  return (
    <div className="admin-body">
      <Banner />
      <Header toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />}

      <div className="admin-reports" id="admin-reports">
        <div className="header-section">
          <h2 className="decorated-heading">Manage Reports</h2>
          <button className="add-report-btn" onClick={() => window.location.href = "/admin/reports/add"}>
            <FontAwesomeIcon icon={faPlus} /> Add Report
          </button>
        </div>
        <hr />

        {data?.length > 0 ? (
          <table className="reports-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Description</th>
                <th>Type</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((report, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{report.Title}</td>
                  <td>{report.Description.substring(0, 100)}...</td>
                  <td>{report.Type}</td>
                  <td>{new Date(report.Date).toLocaleDateString()}</td>
                  <td>
                    <a href={`/api/uploads/reports/${report.File}`} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faEye} className="icon view-icon" title="View Report" />
                    </a>
                    <FontAwesomeIcon icon={faEdit} className="icon edit-icon" title="Edit" />
                    <FontAwesomeIcon icon={faTrash} className="icon delete-icon" title="Delete" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="centertext">
            <h3>No Reports Available</h3>
            <p>Please check back regularly for updates.</p>
          </div>
        )}
        <hr />
      </div>
      
      <Footer />
    </div>
  );
}
