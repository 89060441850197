import "../styles/header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMoon, faSun} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/imgs/giwasco_logo.png"
import { useState, useEffect } from "react";
import Dropdown from "./dropdown";

export default function Header (props) {
    const [isSlightlyScrolled, setIsSlightlyScrolled] = useState(false);
    const [isScrolledByVh, setIsScrolledByVh] = useState(false);
    const [toggleMenu, setToggleMenu] = useState(false);

    // When the user scrolls, run this function...  
    useEffect(() => {
        const handleScroll = () => {
        if (window.pageYOffset > 70 && window.pageYOffset < window.innerHeight+100) {
            setIsSlightlyScrolled(true);
        } else {
            setIsSlightlyScrolled(false);
        }
        
        if (window.pageYOffset > window.innerHeight+100) {
            setIsScrolledByVh(true);
        } else {
            setIsScrolledByVh(false);
        }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleContextMenu = (e) => {
        e.preventDefault();
        };

        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    return (
        <div className= {isScrolledByVh ?  "header scrolledByVh" : isSlightlyScrolled ? "header" : "header nobg"}>
            <div className="navigation">
                <div className="logo">
                    <img src={logo} alt="" />
                    {/* <h1>Githunguri Water & Sanitation Company Ltd.</h1> */}
                </div>
                <div className="navlinks">
                    {/* <p>DISCOVER your world way differently with Hexhus... .. ...Achieve more with us!</p> */}
                    <a href="/">Home</a>
                    {/* <a href="">About</a> */}
                    <Dropdown title="About" items = {
                        {"Who we are" : "/aboutus", 
                            "Our Board of Directors" : "/aboutus/board", 
                            "Our Management Team" : "/aboutus/management", 
                            "Chairperson's Statement" : "/statements/chair", 
                            "Managing Director's Statement" : "/statements/md" }} isSlightlyScrolled={isSlightlyScrolled}/>
                    <a href="/services">Services</a>
                    <Dropdown title="Media" items = {
                        {"News & Events" : "/newsandevents", 
                            "Reports" : "/reports", 
                            // "Downloads & Publications" : "/downloads", 
                            "Gallery" : "/gallery", 
                            // "Blog" : "/blog" 
                        }
                    } isSlightlyScrolled={isSlightlyScrolled}/>
                    <a href="/tenders">Tenders</a>
                    <a href="/careers">Careers</a>
                    <a href="/contactus">Contacts Us</a>
                </div>
                <div className="menu">
                    <FontAwesomeIcon icon={faBars} onClick={() => props.setToggleMenu(!props.toggleMenu)}/>
                </div>
                {/* <div className="mode">
                    <FontAwesomeIcon icon={faSun} />
                </div> */}
            </div>
        </div>
    );
}